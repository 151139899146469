import React from "react"
import News from "../../blocks/news"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"

export const pageQuery = graphql`
query {
  news: newsYaml(slug: { eq: "sorokins_latvia" }) {
    ...NewsInfo
    innerImages {
      callSheet {
        childImageSharp {
          fluid(maxWidth: 1680) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
}`

export default class NewsText extends News {
  renderNews() {
    const { callSheet } = this.props.data.news.innerImages
    return (
      <div className="row">
        <div className="col-xl-12">
          <p><Link to={ `/players/sorokins` }>Владиславс Сорокинс</Link> получил вызов в Национальную сборную Латвии. Поздравляем Владислава и желаем ему скорейшего дебюта!</p>

          <Img fluid={ callSheet.childImageSharp.fluid } alt="Вызов Сорокинса в сборную Латвии" width="100%" />

        </div>
      </div>
    )
  }
}